// extracted by mini-css-extract-plugin
export var h1Typography = "helpdesk-module--h1-typography--yG3YI";
export var h2Typography = "helpdesk-module--h2-typography--4I6m0";
export var h2TypographySecond = "helpdesk-module--h2-typography-second--yfId6";
export var h3Typography = "helpdesk-module--h3-typography--RPMr2";
export var h3TypographyError = "helpdesk-module--h3-typography-error--MHsKQ";
export var h4Typography = "helpdesk-module--h4-typography--ekTfV";
export var pTypography = "helpdesk-module--p-typography--W818m";
export var smallPTypography = "helpdesk-module--small-p-typography--u4dyj";
export var sidebarTypography = "helpdesk-module--sidebar-typography--su-zG";
export var errorTypography = "helpdesk-module--error-typography--5Ggb1";
export var captionTypography = "helpdesk-module--caption-typography--INAep";
export var authMessageLabelTypography = "helpdesk-module--auth-message-label-typography--FEOVn";
export var authMessageTypography = "helpdesk-module--auth-message-typography--1Arx8";
export var versionInfoTypography = "helpdesk-module--version-info-typography--Ng9H9";
export var itemHidden = "helpdesk-module--item-hidden--BsFOB";
export var ___gatsby = "helpdesk-module--___gatsby--spIRX";
export var gatsbyFocusWrapper = "helpdesk-module--gatsby-focus-wrapper---v7Zn";
export var headerContainer = "helpdesk-module--header-container--vwAWP";
export var mobileHeaderText = "helpdesk-module--mobile-header-text--ZmypV";
export var headerText = "helpdesk-module--header-text--oA8QU";
export var baseContainer = "helpdesk-module--base-container--6LOVi";
export var loggedContentContainer = "helpdesk-module--logged-content-container--2mLx-";
export var contentContainer = "helpdesk-module--content-container--569S4";
export var btnContainer = "helpdesk-module--btn-container--uj-V+";
export var contactContainer = "helpdesk-module--contact-container--ERWrz";
export var btnStyle = "helpdesk-module--btn-style--2cizV";
export var contactStyle = "helpdesk-module--contact-style--a4+oi";
export var contactStyleHeader = "helpdesk-module--contact-style-header--ilPS+";
export var emailStyle = "helpdesk-module--email-style--YjP0t";